import styled from "styled-components";

const CarouselStyled = styled.div`
  padding: 0rem 4vw;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 1rem);
  overflow: hidden;
  width: 100vw;

  @media (max-width: 480px) {
    padding-top: 0.5rem;
  }

  & .arrow {
    display: flex;
    justify-content: center;
    margin: 0rem 1rem;
    padding: 1.4rem 0.4rem;
    flex: 3;
    border-radius: 25px;
    transition: background-color 0.35s ease 0s;
    cursor: pointer;

    background-color: transparent;
    color: var(--WHITE);

    &:hover {
      background-color: var(--GOLD);
      color: var(--BLACK);
    }

    .icon {
      font-size: 3rem;
      transition: color 0.4s ease 0s;
    }
  }

  & .carousel-track {
    flex: 94;
    display: flex;
    overflow: hidden;
    cursor: grab;

    &.smooth-scroll {
      transition: scroll-left 0.3s ease-in-out;
      scroll-behavior: smooth;
    }

    .carousel-list {
      display: flex;
      padding: 3px 0rem;
      width: 0;

      .image {
        height: 150px;

        @media (min-width: 768px) and (max-width: 1024px) {
          height: 130px;
        }
        @media (min-width: 480px) and (max-width: 768px) {
          height: 110px;
        }
        @media (max-width: 480px) {
          height: 100px;
        }

        flex-shrink: 1;
        margin: 0px 5px;

        user-select: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;

        &.selected {
          outline: 3px solid var(--GOLD);
          box-sizing: "content-box";
        }
      }
    }
  }
`;

export default CarouselStyled;
