import "./app.css";
import Nawigacja from "./components/Nawigacja";
import MainSite from "./components/MainSite";
import ZrealizowaneProjekty from "./components/ZrealizowaneProjekty";
import Stopka from "./components/Stopka";
import {createBrowserRouter, RouterProvider, Outlet} from "react-router-dom";
import {
  zrealizowaneProjektyLoader,
  mainSiteLoader,
} from "./store/utils/loaders";
import {Provider} from "react-redux";
import store from "./store/reducer/reducer-index";

const Layout = () => {
  return (
    <>
      <Nawigacja />
      <Outlet />
      <Stopka />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/zrealizowane_projekty",
        element: <ZrealizowaneProjekty />,
        errorElement: <p>Error</p>,
        loader: zrealizowaneProjektyLoader,
      },
      {
        path: "/",
        element: <MainSite />,
        errorElement: <p>Error</p>,
        loader: mainSiteLoader,
      },
    ],
  },
]);

function App() {
  return (
    <Provider store={store}>
      <div className="app">
        <RouterProvider router={router} />
      </div>
    </Provider>
  );
}

export default App;
