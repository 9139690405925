import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {viewActions} from "../store/reducer/slice-view";
import HamburgerMenuStyled from "./styles/HamburgerMenuStyled";

function HamburgerMenu() {
  const dispatch = useDispatch();
  const navHeight = useSelector((state) => state.view.navHeight);
  const currentSite = useSelector((state) => state.currentSite);
  const siteContent = useSelector((state) => state.siteContent)[currentSite];

  const closeMenu = () => {
    dispatch(viewActions.setIsMenuOpened(false));
  };

  const getMenuLink = (text, link, isNavLink) => {
    if (isNavLink) {
      return (
        <NavLink to={link} onClick={closeMenu}>
          {text}
        </NavLink>
      );
    }

    return (
      <a href={link} onClick={closeMenu}>
        {text}
      </a>
    );
  };

  return (
    <HamburgerMenuStyled $nav_heigth={navHeight}>
      <div className="menu_list-container">
        {siteContent.offer && getMenuLink("OFERTA", "#offer")}
        {siteContent.discount && getMenuLink("PROMOCJA", "#discount")}
        {siteContent.gallery && getMenuLink("GALERIA", "#gallery")}
        {siteContent.technicals && getMenuLink("DANE TECHNICZNE", "#detailed")}
        {siteContent.actual_offer && getMenuLink("AKTUALNA OFERTA", "/", true)}
        {siteContent.completed_projects &&
          getMenuLink("ZREALIZOWANE PROJEKTY", "/zrealizowane_projekty", true)}
        {siteContent.contact && getMenuLink("KONTAKT", "#contact")}
      </div>
    </HamburgerMenuStyled>
  );
}

export default HamburgerMenu;
