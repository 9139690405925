import {configureStore} from "@reduxjs/toolkit";
import currentSiteReducer from "./slice-current_site";
import siteContentReducer from "./slice-site_content";
import galleryReducer from "./slice-gallery.ts";
import viewReducer from "./slice-view";

const store = configureStore({
  reducer: {
    currentSite: currentSiteReducer,
    siteContent: siteContentReducer,
    gallery: galleryReducer,
    view: viewReducer,
  },
});

export default store;
