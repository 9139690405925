import LiStyled from "./styles/LiStyled";
import {FaCheck} from "react-icons/fa";

function Li(props) {
  return (
    <LiStyled>
      <div className="icon-container">
        {props.check === false ? <></> : <FaCheck className="li-icon" />}
      </div>
      <span className="content">{props.children}</span>
    </LiStyled>
  );
}

export default Li;
