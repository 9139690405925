import styled from "styled-components";

const HamburgerMenuStyled = styled.div`
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  animation: slideInFromRight 0.3s ease-out forwards;

  display: block;
  position: fixed;
  min-width: 480px;

  @media (max-width: 480px) {
    min-width: 100%;
  }

  top: ${(props) => `${props.$nav_heigth}px`};
  right: 0px;
  @media (min-width: 1024px) {
    display: none;
  }

  .menu_list-container {
    display: flex;
    flex-direction: column;
    background-color: var(--GOLD);

    a {
      cursor: pointer;
      font-size: 18px;
      font-family: raleway, sans-serif;
      /* font-weight: bold; */
      color: var(--BLACK);
      padding: 1rem 2vw;
      margin: 0px;
      background-color: var(--GOLD);
      border: none;
      outline: none;
      transition: color 0.35s ease 0s;
      text-decoration: none;
      text-align: center;
    }
  }
`;

export default HamburgerMenuStyled;
