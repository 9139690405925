import FooterStyled from "./styles/FooterStyled";
import {HiMiniDevicePhoneMobile} from "react-icons/hi2";
import {MdOutlineLocalPostOffice} from "react-icons/md";
import {RxDoubleArrowRight} from "react-icons/rx";

function Footer() {
  return (
    <FooterStyled id="contact">
      <div className="teaser">
        <h1>Zadaj pytanie</h1>
        <h2>Skorzystaj z promocji !</h2>
      </div>
      <div className="arrow">
        <RxDoubleArrowRight />
      </div>
      <div className="contact">
        <h1>Kontakt:</h1>
        <div className="contact-data">
          <div className="contact-icon">
            <HiMiniDevicePhoneMobile />
          </div>
          <p>504 211 211</p>
        </div>
        <div className="contact-data">
          <div className="contact-icon">
            <MdOutlineLocalPostOffice />
          </div>
          <p>mwpatron@wp.pl</p>
        </div>
      </div>
    </FooterStyled>
  );
}

export default Footer;
