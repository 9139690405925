import styled from "styled-components";

const GalleryStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--BLACK);
  margin: 0px;
  width: 100vw;

  & h1 {
    padding: 0px;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    margin: 0px;
    font-size: 5.4rem;
    text-shadow: 3px 1px 5px rgba(0, 0, 0, 0.7);
    color: var(--GOLD);

    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 4rem;
      text-shadow: 3px 1px 3px rgba(0, 0, 0, 0.7);
    }
    @media (min-width: 480px) and (max-width: 768px) {
      font-size: 3.5rem;
      text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.7);
    }
    @media (max-width: 480px) {
      font-size: 3rem;
      text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.7);
    }
  }
`;

export default GalleryStyled;
