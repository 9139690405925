import store from "../reducer/reducer-index";
import {siteActions} from "../reducer/slice-current_site";

export function mainSiteLoader() {
  store.dispatch(siteActions.setCurrentSite("main_site"));
  return null;
}

export function zrealizowaneProjektyLoader() {
  store.dispatch(siteActions.setCurrentSite("zrealizowane_projekty"));
  return null;
}
