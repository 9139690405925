import GaleriaStyled from "./styles/GaleriaStyled";
import Section from "../../common/Section";
import PhotoGallery from "../../other/PhotoGallery.tsx";

function Gallery() {
  return (
    <Section id="gallery" className="primary-secondary">
      <GaleriaStyled>
        <h1>Galeria</h1>
        <PhotoGallery
          gallery_name={"main_site"}
          carousel={true}
          carousel_arrows={false}
          arrows={"WHITE"}
          hover={"GOLD"}
        ></PhotoGallery>
      </GaleriaStyled>
    </Section>
  );
}

export default Gallery;
