import styled from "styled-components";

const SubtitleStyled = styled.h1`
  padding-top: 2rem;
  padding-bottom: 1.5rem;

  margin: 0px;
  font-size: 4rem;
  text-shadow: 1px 0px 3px rgba(0, 0, 0, 0.5);

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 3rem;
    text-shadow: 1px 0px 3px rgba(0, 0, 0, 0.5);
  }
  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 2rem;
    text-shadow: 1px 0px 3px rgba(0, 0, 0, 0.5);
  }
  @media (max-width: 480px) {
    font-size: 1.5rem;
    text-shadow: 1px 0px 3px rgba(0, 0, 0, 0.5);
  }
`;

export default SubtitleStyled;
