import Title from "../../common/Title";
import Blok from "../../common/Blok";
import {FaPhone} from "react-icons/fa";
import Section from "../../common/Section";

function Sale() {
  return (
    <Section id="discount" className="sale">
      <Title className="sale">Promocja</Title>
      <Blok className="wide_window">
        <p className="promocja">
          RABAT 10 000 zł dla pierwszych klientów - OKAZJA!!!
        </p>
        <p>
          Regularna cena to 515 000 zł. W chwili obecnej obowiązuje cena
          promocyjna 505 000 zł.
        </p>
        <a href="#contact">
          <FaPhone className="icon" />
          ZADZWOŃ TERAZ i zarezerwój zniżkę dla siebie!
        </a>
        <p className="promocja">ZAPRASZAMY!!!</p>
      </Blok>
    </Section>
  );
}

export default Sale;
