import React, {useEffect} from "react";
import PhotoGalleryStyled from "./styles/PhotoGalleryStyled";
import Carousel from "./Carousel";
import {useSelector, useDispatch} from "react-redux";
import {galleryActions} from "../../store/reducer/slice-gallery.ts";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import Img from "../common/Img.tsx";
import {GalleryData, GalleryImage, RootState} from "../../store/types/Reducer";
import {createSizesStr} from "../../store/utils/functions.ts";
import {PhotoGalleryProps} from "../../store/types/Props";

function PhotoGallery(props: PhotoGalleryProps) {
  const dispatch = useDispatch();

  const {images, selectedIndex}: GalleryData = useSelector(
    (state: RootState) => state.gallery[props.gallery_name]
  );
  const leftArrowId = `${props.gallery_name}_gallery_arrow_left`;
  const rightArrowId = `${props.gallery_name}_gallery_arrow_right`;

  useEffect(() => {
    const leftArrow = document.getElementById(leftArrowId);
    const rightArrow = document.getElementById(rightArrowId);

    leftArrow?.addEventListener("click", () => {
      dispatch(
        galleryActions.setImgIndex({
          galleryName: props.gallery_name,
          index: "prev",
        })
      );
    });

    rightArrow?.addEventListener("click", () => {
      dispatch(
        galleryActions.setImgIndex({
          galleryName: props.gallery_name,
          index: "next",
        })
      );
    });
  }, [dispatch, leftArrowId, rightArrowId, props.gallery_name]);

  const arrowLeft = (
    <div className="arrowBtn_container left_arrow">
      <IoIosArrowBack id={leftArrowId} className="arrow-icon" />
    </div>
  );

  const arrowRight = (
    <div className="arrowBtn_container right_arrow">
      <IoIosArrowForward id={rightArrowId} className="arrow-icon" />
    </div>
  );

  const renderSelectedImg = (img: GalleryImage) => {
    return (
      <div className="img_container">
        {arrowLeft}
        <Img
          src={img}
          sizes={createSizesStr([
            {max: 768, width: "100vw"},
            {max: "", width: "60vw"},
          ])}
          alt="Selected"
        />
        {arrowRight}
      </div>
    );
  };

  const mainImg = renderSelectedImg(images[selectedIndex]);

  const carousel = props.carousel && (
    <Carousel
      images={images}
      arrows={props.carousel_arrows}
      selectedImg={images[selectedIndex]}
      gallery_name={props.gallery_name}
    ></Carousel>
  );

  return (
    <PhotoGalleryStyled>
      <div
        className={`main-img-container arrows_${props.arrows} hover_${props.hover}`}
        id="gallery"
      >
        {mainImg}
      </div>
      {carousel}
    </PhotoGalleryStyled>
  );
}

export default PhotoGallery;
