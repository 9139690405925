import Blok from "../../common/Blok";
import BOLD from "../../common/BOLD";
import Section from "../../common/Section";
import Title from "../../common/Title";
import PhotoGallery from "../../other/PhotoGallery.tsx";

function Linarczyk(props) {
  return (
    <Section id="linarczyk">
      <Title>Linarczyk</Title>
      <Blok className="text">
        <p>
          Zrealizowany projekt w Linarczyku. Zrealizowany projekt w Linarczyku.
          Zrealizowany projekt w Linarczyku. Zrealizowany projekt w Linarczyku.
          Zrealizowany projekt w Linarczyku. Zrealizowany projekt w Linarczyku.{" "}
          <BOLD>Zrealizowany projekt w Linarczyku.</BOLD>Zrealizowany projekt w
          Linarczyku. Zrealizowany projekt w Linarczyku. Zrealizowany projekt w
          Linarczyku.
        </p>
      </Blok>
      <PhotoGallery
        gallery_name={"linarczyk"}
        arrows={props.arrows}
        hover={props.hover}
      ></PhotoGallery>
    </Section>
  );
}

export default Linarczyk;
