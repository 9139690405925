import styled from "styled-components";

const TitleStyled = styled.h1`
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--BLACK);
  margin: 0px 5vw;
  font-size: 5.5rem;
  text-shadow: 3px 1px 5px rgba(0, 0, 0, 0.7);
  text-align: center;

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 4rem;
    text-shadow: 3px 1px 3px rgba(0, 0, 0, 0.7);
  }
  @media (min-width: 480px) and (max-width: 768px) {
    font-size: 3rem;
    text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.7);
  }
  @media (max-width: 480px) {
    font-size: 2.5rem;
    text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.7);
  }

  &.sale {
    color: var(--promocja-color);
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5),
      1px 1px 5px var(--promocja-shadow), 1px 1px 4px var(--promocja-color);

    @media (min-width: 768px) and (max-width: 1024px) {
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5),
        1px 1px 4px var(--promocja-shadow), 1px 1px 4px var(--promocja-color);
    }
    @media (min-width: 480px) and (max-width: 768px) {
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5),
        1px 1px 3px var(--promocja-shadow), 1px 1px 2px var(--promocja-color);
    }

    @media (max-width: 480px) {
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5),
        1px 1px 2px var(--promocja-shadow), 1px 1px 2px var(--promocja-color);
    }
  }

  &.primary_bg {
    background-color: var(--BLACK);
  }
  &.secondary_bg {
    background-color: var(--GOLD);
  }
  &.third_bg {
    background-color: var(--WHITE);
  }

  &.primary_f {
    color: var(--BLACK);
  }
  &.secondary_f {
    color: var(--GOLD);
  }
  &.third_f {
    color: var(--WHITE);
  }
`;

export default TitleStyled;
