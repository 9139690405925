import {createSlice} from "@reduxjs/toolkit";

const viewSlice = createSlice({
  name: "view",
  initialState: {navHeight: null, isMenuOpened: false},
  reducers: {
    setNavHeigth: (state, action) => {
      state.navHeight = action.payload;
    },
    setIsMenuOpened: (state, action) => {
      state.isMenuOpened = action.payload;
    },
    toggleIsMenuOpened: (state) => {
      state.isMenuOpened = !state.isMenuOpened;
    },
  },
});

export const viewActions = viewSlice.actions;
export default viewSlice.reducer;
