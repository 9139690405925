import styled from "styled-components";

const PhotoGalleryStyled = styled.div`
  .main-img-container {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;

    @media (max-width: 768px) {
      /* column-gap: 0px; */
    }

    .img_container {
      position: relative;
      width: 60vw;
      height: 60vh;

      @media (max-width: 768px) {
        width: 100vw;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .arrowBtn_container {
        position: absolute;
        transition: transform 0.4s ease, color ease 0.4s;
        transform: scale(1);

        &:hover {
          transform: scale(0.9);

          @media (max-width: 768px) {
            transform: none;
          }
        }

        &.left_arrow {
          top: 50%;
          left: -10%;
          box-shadow: 0 3px 6px var(--SHADOW);

          @media (max-width: 768px) {
            left: 5%;
          }
          @media (max-width: 480px) {
            left: 2rem;
          }

          path {
            transform: translate(-5%, 0%);
          }
        }

        &.right_arrow {
          top: 50%;
          right: -10%;
          box-shadow: 0 3px 6px var(--SHADOW);

          @media (max-width: 768px) {
            right: 5%;
          }
          @media (max-width: 480px) {
            right: 2rem;
          }

          path {
            transform: translate(5%, 0%);
          }
        }

        .arrow-icon {
          border-radius: 50%;
          position: absolute;
          font-size: 4.5rem;
          cursor: pointer;
          padding: 0px;
          margin: 0px;
          transform: translate(-50%, -50%);

          @media (max-width: 768px) {
            font-size: 2.5rem;
            padding: 0.4rem;
            background-color: var(--GOLD);
            color: var(--BLACK);
          }

          &:active {
            outline: none;
            box-shadow: none;
            color: var(--GOLD);

            @media (max-width: 768px) {
              color: var(--BLACK);
            }
          }
        }
      }
    }
  }

  .arrows_BLACK .arrowBtn_container {
    color: var(--BLACK);
  }

  .arrows_GOLD .arrowBtn_container {
    color: var(--GOLD);
  }

  .arrows_WHITE .arrowBtn_container {
    color: var(--WHITE);
  }

  .hover_BLACK .arrowBtn_container {
    &:active {
      color: var(--BLACK);
    }
  }

  .hover_GOLD .arrowBtn_container {
    &:active {
      color: var(--GOLD);
    }
  }

  .hover_WHITE .arrowBtn_container {
    &:active {
      color: var(--WHITE);
    }
  }
`;

export default PhotoGalleryStyled;
