import IntroStyled from "./styles/IntroStyled";
import Img from "../../common/Img";
import {createSizesStr} from "../../../store/utils/functions";
import {useSelector} from "react-redux";

function Intro() {
  const gallery = useSelector((state) => state.gallery["main_bg"]);

  return (
    <IntroStyled>
      <div className="main_bg">
        <Img
          src={gallery.images[0]}
          alt="bg"
          sizes={createSizesStr([{max: "", width: "100vw"}])}
        />
      </div>
      <a href="#offer" className="main-title">
        <h1>Wiczarski Deweloper</h1>
        <p>Przestrzeń idealna dla Ciebie.</p>
      </a>
    </IntroStyled>
  );
}

export default Intro;
