import styled from "styled-components";

const NavStyled = styled.div`
  position: fixed;
  top: 0px;
  padding-left: 4vw;
  padding-right: 2.5vw;
  background-color: var(--nav-bg);
  width: 100%;
  height: 5.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 1rem;

    color: var(--WHITE);
    text-decoration: none;
    transition: color 0.35s ease 0s;

    .logo-img-container {
      position: relative;

      img {
        height: 3.4rem;
        width: auto;

        @media (max-width: 480px) {
          height: 3rem;
        }
      }

      h1 {
        position: absolute;
        top: 10px;
        right: 0;
        margin: 0px;
        padding: 0px;
        font-size: 1rem;
        font-family: Arial, sans-serif;

        @media (max-width: 480px) {
          top: 9px;
          right: 0;
          font-size: 0.9rem;
        }
      }
    }

    & #logoAlt {
      text-decoration: none;

      h1 {
        cursor: pointer;
        margin: 0px;
        padding: 0px;
        font-size: 1.7em;
        font-weight: lighter;
        font-family: raleway, sans-serif;
        text-align: center;
        line-height: 1.5rem;
      }
    }
  }

  .buttonsBar {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
    }

    justify-content: flex-end;
    /* min-width: 600px; */
    overflow: visible;
    align-items: center;
    width: 100%;

    a {
      position: relative;
      cursor: pointer;
      font-size: 0.9rem;
      font-family: raleway, sans-serif;
      font-weight: bold;
      color: var(--WHITE);
      padding: 0px 1.35vw;
      background: none;
      border: none;
      transition: color 0.35s ease 0s;
      text-decoration: none;
      text-align: center;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.contact {
        font-weight: 900;
      }

      @media (hover: hover) {
        &:hover {
          color: var(--GOLD);
        }
      }

      & h1.hidden {
        visibility: hidden;
      }

      & img {
        position: absolute;
        top: 85%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 120px;
        pointer-events: none;
        filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.7));

        &:active {
          outline: none;
        }

        &.contact {
          font-weight: 900;
        }
      }

      & h1 {
        margin: 0;
        padding: 0;
        font-size: 0.9rem;
        font-family: raleway, sans-serif;
        font-weight: bold;
      }

      & h1.text_on_top {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, 0);

        width: 100%;
        transition: color 0.35s ease 0s;
        text-shadow: 1px 1px 2px black, 0 0 1em red, 0 0 0.2em darkred;
        cursor: pointer;
        color: var(--WHITE);
        background: none;
        border: none;
        transition: color 0.35s ease 0s;
        text-decoration: none;
        text-align: center;
      }
    }
  }

  .navHamburger {
    display: none;
    @media (max-width: 1023px) {
      display: block;
    }

    padding: 0.25rem 0.5rem;
    margin-right: 1.5vw;

    & .icon {
      color: var(--WHITE);
      font-size: 2rem;
    }
  }
`;

export default NavStyled;
