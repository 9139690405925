import {createSlice} from "@reduxjs/toolkit";
import {siteContentSettings} from "../settings/settings";

const siteContentSlice = createSlice({
  name: "siteContent",
  initialState: siteContentSettings,
  reducers: {
    setSiteContent: (state, action) => {
      state.siteContent = action.payload;
    },
  },
});

export const siteActions = siteContentSlice.actions;
export default siteContentSlice.reducer;
