import {createSlice} from "@reduxjs/toolkit";

const currentSiteSlice = createSlice({
  name: "currentSite",
  initialState: "",
  reducers: {
    setCurrentSite: (_, action) => {
      return action.payload;
    },
  },
});

export const siteActions = currentSiteSlice.actions;
export default currentSiteSlice.reducer;
