import styled from "styled-components";

const PSStyled = styled.span`
  color: green;
  font-weight: bolder;
  display: inline;

  display: inline;
  /* white-space: pre; */
`;

export default PSStyled;
