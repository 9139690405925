import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import CarouselStyled from "./styles/CarouselStyled";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {galleryActions} from "../../store/reducer/slice-gallery.ts";
import Img from "../common/Img.tsx";

function Carousel(props) {
  const dispatch = useDispatch();
  const [hasMoved, setHasMoved] = useState(false);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setHasMoved(false);
  };

  const handleMouseMove = () => {
    if (!hasMoved) {
      setHasMoved(true);
    }
  };

  const createImgElement = (image, index) => {
    const imgName = image.name;
    const selectedImgName = props.selectedImg.name;

    return (
      <Img
        key={index}
        className={`image ${imgName === selectedImgName ? "selected" : ""}`}
        src={image}
        alt={`${index + 1}`}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onClick={() => {
          dispatch(
            galleryActions.setImgIndex({
              galleryName: props.gallery_name,
              index,
            })
          );

          if (!hasMoved) {
          }
        }}
        dataIndex={index}
      />
    );
  };

  const imagesList = props.images.map((image, index) =>
    createImgElement(image, index)
  );

  useEffect(() => {
    const carousel = document.querySelector(".carousel-track");
    let isDown = false;
    let startX;
    let scrollLeft;

    const visibleWidth = carousel.clientWidth;

    const prevButton = document.getElementById("arrow_left");
    const nextButton = document.getElementById("arrow_right");

    const handlePrevClick = () => {
      carousel.classList.add("smooth-scroll");
      carousel.scrollLeft -= visibleWidth / 2;

      setTimeout(() => {
        carousel.classList.remove("smooth-scroll");
      }, 400);
    };

    const handleNextClick = () => {
      carousel.classList.add("smooth-scroll");
      carousel.scrollLeft += visibleWidth / 2;

      setTimeout(() => {
        carousel.classList.remove("smooth-scroll");
      }, 300);
    };

    const handleMouseDown = (e) => {
      isDown = true;
      startX = e.pageX - carousel.offsetLeft;
      scrollLeft = carousel.scrollLeft;
      carousel.style.cursor = "grabbing";
    };

    const handleMouseLeave = () => {
      isDown = false;
      carousel.style.cursor = "grab";
    };

    const handleMouseUp = () => {
      isDown = false;
      carousel.style.cursor = "grab";
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - carousel.offsetLeft;
      const walk = (x - startX) * 1.5;
      carousel.scrollLeft = scrollLeft - walk;
    };

    const handleTouchStart = (e) => {
      isDown = true;
      startX = e.touches[0].pageX - carousel.offsetLeft;
      scrollLeft = carousel.scrollLeft;
      carousel.style.cursor = "grabbing";
    };

    const handleTouchEnd = () => {
      isDown = false;
      carousel.style.cursor = "grab";
    };

    const handleTouchMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.touches[0].pageX - carousel.offsetLeft;
      const walk = (x - startX) * 1.5;
      carousel.scrollLeft = scrollLeft - walk;
    };

    prevButton?.addEventListener("click", handlePrevClick);
    nextButton?.addEventListener("click", handleNextClick);

    carousel.addEventListener("mousedown", handleMouseDown);
    carousel.addEventListener("mouseleave", handleMouseLeave);
    carousel.addEventListener("mouseup", handleMouseUp);
    carousel.addEventListener("mousemove", handleMouseMove);

    carousel.addEventListener("touchstart", handleTouchStart);
    carousel.addEventListener("touchend", handleTouchEnd);
    carousel.addEventListener("touchmove", handleTouchMove);

    return () => {
      carousel.removeEventListener("mousedown", handleMouseDown);
      carousel.removeEventListener("mouseleave", handleMouseLeave);
      carousel.removeEventListener("mouseup", handleMouseUp);
      carousel.removeEventListener("mousemove", handleMouseMove);

      carousel.removeEventListener("touchstart", handleTouchStart);
      carousel.removeEventListener("touchend", handleTouchEnd);
      carousel.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  const arrowLeft = (
    <div id="arrow_left" className="arrow">
      <MdOutlineKeyboardArrowLeft className="icon" />
    </div>
  );

  const arrowRight = (
    <div id="arrow_right" className="arrow">
      <MdOutlineKeyboardArrowRight className="icon" />
    </div>
  );

  return (
    <CarouselStyled>
      {props.arrows && arrowLeft}
      <div className="carousel-track">
        <div className="carousel-list">{imagesList}</div>
      </div>
      {props.arrows && arrowRight}
    </CarouselStyled>
  );
}

export default Carousel;
