import SectionStyled from "./styles/SectionStyled";

function Section(props) {
  return (
    <section id={props.id}>
      <SectionStyled className={props.className}>
        {props.children}
      </SectionStyled>
    </section>
  );
}

export default Section;
