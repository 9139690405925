import React from "react";
import Blok from "../../common/Blok";
import Title from "../../common/Title";
import Section from "../../common/Section";
import Img from "../../common/Img.tsx";
import {createSizesStr} from "../../../store/utils/functions.ts";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/types/Reducer";

function Offer() {
  const gallery = useSelector((state: RootState) => state.gallery["offer"]);

  return (
    <Section id="offer" className="offer third-primary">
      <Title>Oferta</Title>
      <Img
        src={gallery.images[0]}
        sizes={createSizesStr([
          {max: 768, width: "80vw"},
          {max: 1024, width: "70vw"},
          {max: "", width: "80vw"},
        ])}
        alt="Dom"
      />
      <Blok className="text">
        <p>
          Prowadzimy sprzedaż domów na kameralnym osiedlu w Węgrowie pod
          Grudziądzem. Mamy przyjemność zaoferować Państwu nowoczesne, o{" "}
          <span className="bolder">podwyższonym standardzie</span>,
          energooszczędne domy o powierzchni 103 m2, w Węgrowie 2 km od
          Grudziądza, w zabudowie bliźniaczej, posiadających wiele przemyślanych
          rozwiązań i praktycznych udogodnień. Połączyliśmy nowoczesność z
          funkcjonalnością, jednocześnie wykorzystując każdy metr kwadratowy
          każdego domu. Proponując wysoki standard wykończenia oraz staranność
          wykonania jednocześnie utrzymaliśmy atrakcyjną cenę. Domy są w stanie
          deweloperskim zamkniętym. Wszystkie przyłącza i instalacje (prąd,
          woda, gaz, kanalizacja, ogrzewanie) w pełni funkcjonują i po dokonaniu
          prac wykończeniowych w środku, domy nadają się do zamieszkania.
        </p>
      </Blok>
    </Section>
  );
}

export default Offer;
