import styled from "styled-components";

const LiStyled = styled.li`
  margin: 0.8em 0px;
  padding: 0px;
  display: flex;
  padding-bottom: 0.8rem;

  & .icon-container {
    .li-icon {
      margin-right: 5px;
      font-size: 17px;
    }
  }

  & .content {
    padding: 0px;
    margin: 0px;

    & span.hs {
      color: var(--special-c);
      font-weight: bolder;
      white-space: pre;
    }

    & span.bolder {
      font-weight: bolder;
    }
  }
`;

export default LiStyled;
