import styled from "styled-components";

const OknoStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0;
  width: 440px;
  /* min-height: 500px; */
  border: var(--BLACK) solid 1px;
  height: auto;

  @media (min-width: 1900px) {
    width: 550px;
    padding: 2rem 0px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 40vw;
    padding: 2rem 0px;
    min-height: 350px;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    width: 80vw;

    padding: 2rem 0px;
    min-height: 300px;
  }
  @media (max-width: 480px) {
    width: 90vw;
    padding: 2rem 0px;
    min-height: 250px;
  }

  & h1 {
    text-align: center;
    margin: 0rem 4rem;
    margin-bottom: 1.5rem;
    font-size: 28px;
    font-weight: bolder;

    @media (min-width: 1900px) {
      font-size: 40px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 24px;
    }
    @media (min-width: 480px) and (max-width: 768px) {
      font-size: 26px;
    }
    @media (max-width: 480px) {
      margin: 0rem 1.3rem;
      margin-bottom: 1rem;
      font-size: 24px;
    }
  }

  & .li-container {
    margin: 0px;
    padding: 0px 3.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @media (min-width: 1900px) {
      padding: 0px 3rem;
    }
    @media (min-width: 1024px) and (max-width: 1200px) {
      padding: 0px 3rem;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 0px 3rem;
    }
    @media (min-width: 480px) and (max-width: 768px) {
      padding: 0px 2rem;
    }
    @media (max-width: 480px) {
      padding: 0px 1.5rem;
    }

    & li {
      font-size: 1.2rem;
      margin-top: 0.3vw;
      margin-bottom: 0.7rem;
      margin-left: 0.5rem;

      & .bolder {
        font-weight: bolder;
      }
    }
  }
`;

export default OknoStyled;
