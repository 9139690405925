export const siteContentSettings = {
  general: {
    srcsetWidths: [320, 480, 768, 1024, 1280, 1600, 1920],
  },
  main_site: {
    logo: true,
    nav: true,
    intro: true,
    offer: true,
    discount: true,
    description: true,
    gallery: true,
    technicals: true,
    completed_projects: true,
    contact: true,
    actual_offer: false,
  },
  zrealizowane_projekty: {
    logo: true,
    nav: true,
    intro: false,
    offer: false,
    discount: false,
    description: false,
    gallery: false,
    technicals: false,
    completed_projects: false,
    contact: true,
    actual_offer: true,
  },
};
