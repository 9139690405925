import Blok from "../../common/Blok";
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import PS from "../../common/PS";
import Li from "../../common/Li";
import BOLD from "../../common/BOLD";
import Okno from "../../common/Okno";
import Section from "../../common/Section";

function Detailed() {
  return (
    <Section id="detailed" className="third-primary">
      <Title>Dane techniczne</Title>
      <Subtitle>Opis nieruchomości:</Subtitle>
      <Blok className="windows">
        <Okno title="Powierzchnie działek:">
          <li>
            ulica Fiołkowa – 3 domy - 500 m2 (1 dom w tej lokalizacji jest
            sprzedany)
          </li>
          <li>ulica Przytulna – 6 domów - 500 m2</li>
          <li>ulica Przytulna – 2 domy - 650 m2</li>
        </Okno>
        <Okno title="Powierzchnie pomieszczeń parter:">
          <li>Wiatrołap – 4,0 m2</li>
          <li>Łazienka 1 – 3,3 m2</li>
          <li>Komunikacja – 3,1 m2</li>
          <li>Pomieszczenie Gospodarcze – 4,3 m2</li>
          <li>Pokój (gabinet) – 6,4 m2</li>
          <li>Kuchnia – 7,1 m2</li>
          <li>Salon – 26,0 m2</li>
          <li>Schody – 4,9 m2</li>
        </Okno>
        <Okno title="Powierzchnie pomieszczeń piętro:">
          <li>Pokój 1 – 9,5 m2</li>
          <li>Pokój 2 – 11,6 m2</li>
          <li>Pokój 3 – 11,8 m2</li>
          <li>Łazienka – 8,8 m2</li>
          <li>Komunikacja – 2,2 m2</li>
        </Okno>
        <Okno title="Przestronny Strych:">
          <li>Schody strychowe - pomieszczenie rewizyjne</li>
        </Okno>
      </Blok>
      <Subtitle>Ważne informacje:</Subtitle>
      <Blok className="wide_window">
        <Li>Okna PCV kolor antracyt, 3-szybowe, 5-cio komorowe</Li>
        <Li>Duże okno tarasowe - wyjście z salonu do ogrodu</Li>
        <Li>
          Dwu funkcyjny piec gazowy o mocy 20 kW (ciepła woda, ogrzewanie
          podłogowe)
        </Li>
        <Li>
          <PS />
          Rolety zewnętrzne we wszystkich oknach
        </Li>
        <Li>
          <PS />
          Sterowanie rolet - podnoszone i opuszczane - elektrycznie
        </Li>
        <Li>
          <PS />
          Schody na poddasze (pomieszczenie rewizyjne)
        </Li>
        <Li>
          <PS />
          Instalacja C.O. - ogrzewanie podłogowe w całym domu (parter + piętro)
        </Li>
        <Li>
          <PS />
          Rozprowadzona instalacja alarmowa (okablowanie), m.in. do syreny
          alarmowej wewnątrz i na zewnątrz domu
        </Li>
      </Blok>
      <Subtitle>Informacje uzupełniające:</Subtitle>
      <Blok className="wide_window">
        <Li>
          Instalacja elektryczna, 3-fazowe podejście pod kuchenkę elektryczną,
          puszki przygotowane do białego montażu
        </Li>
        <Li>
          <PS />
          Duża ilość gniazdek elektrycznych w pokojach
        </Li>
        <Li>
          <PS />
          Instalacja internetowa - okablowanie LAN - we wszystkich pięciu
          pokojach
        </Li>
        <Li>
          <PS />
          Instalacja TV - okablowanie - we wszystkich pięciu pokojach
        </Li>
        <Li>
          Okablowanie na zewnątrz do telewizji satelity (4 przewody) oraz
          dodatkowy przewód TV do odbioru telewizji naziemnej
        </Li>
        <Li>
          Okablowanie zewnętrzne: 220V do gniazd (m.in. pod zasilanie bramy
          wjazdowej), 220V do oświetlenia
        </Li>
        <Li>
          <PS />
          Okablowanie zewnętrzne: skrętka do wideodomofonu, zewnętrzne gniazdko
          elektryczne - przy wyjściu na taras
        </Li>
        <Li>
          Punkty świetlne na zewnątrz domu: z przodu przy drzwiach wejściowych,
          z boku domu, z tylu domu przy tarasie x 2
        </Li>
        <Li>
          <PS />
          Wyprowadzony na zewnątrz punkt poboru wody z dodatkową instalacją i
          podlicznikiem
        </Li>
        <Li>
          <PS />
          Instalacja umożliwiająca montaż stacji zmiękczania wody
        </Li>
        <Li>
          <PS />W 2 łazienkach możliwość zamontowania prysznica z odpływem
          liniowym
        </Li>
        <Li>W 2 łazienkach możliwość korzystania z pralki</Li>
        <Li>
          <PS />
          Instalacja pod czujniki temperatury we wszystkich 5 pokojach –
          możliwość podłączenia automatycznego sterowania ogrzewania podłogowego
        </Li>
        <Li>
          Możliwość zagospodarowania powierzchni pod schodami na piętro – od
          strony salonu lub od strony pomieszczenia gospodarczego
        </Li>
        <Li>Przyłącze energetyczne (skrzynka w linii ogrodzenia)</Li>
        <Li>Przyłącze gazu ziemnego (skrzynka w linii ogrodzenia)</Li>
        <Li>Przyłącze wody z licznikiem w pomieszczeniu gospodarczym</Li>
        <Li>
          Przyłącze kanalizacji wraz z przepompownią ze zbiornikiem średnicy 80
          cm (osobne zasilanie i sterowanie)
        </Li>
      </Blok>

      <Subtitle>Sprzedaż / Kontakt :</Subtitle>
      <Blok className="wide_window">
        <Li check={false}>
          Za dodatkową opłatą wykonujemy prace związane{" "}
          <BOLD>
            z położeniem kostki brukowej i chodniczków, wykonaniem tarasu oraz
            montażem ogrodzenia
          </BOLD>
          .
        </Li>
        <Li check={false}>
          <BOLD>Promocyjna cena domu 505.000 zł </BOLD>
          (regularna cena 515.000 zł)
        </Li>
        <Li check={false}>
          <BOLD>Prowizja 0%</BOLD> - kupno bezpośrednio od dewelopera
        </Li>
        <Li check={false}>
          <BOLD>Kupujący nie płaci 2% podatku PCC</BOLD> – podatek opłaca
          deweloper
        </Li>
        <Li check={false}>Niskie koszty notarialne</Li>
        <Li check={false}>
          Pomagamy klientom uzyskać <BOLD>finansowanie</BOLD> - współpracujemy z
          doradcami <BOLD>kredytowymi</BOLD>.
        </Li>
        <Li check={false}>
          <BOLD>Kontakt:</BOLD>
          <br />
          <BOLD>Maciej Wiczarski</BOLD>
          <br />
          tel. <BOLD>+48 504-211-211</BOLD> (dostępny również na WhatsApp)
          <br />
          Adres e-mail: <BOLD>mwpatron@wp.pl</BOLD>
        </Li>
      </Blok>
    </Section>
  );
}

export default Detailed;
