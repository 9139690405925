import OknoStyled from "./styles/OknoStyled";

function Okno(props) {
  return (
    <OknoStyled className={props.className}>
      <h1>{props.title}</h1>
      <div className="li-container">{props.children}</div>
    </OknoStyled>
  );
}

export default Okno;
