import Blok from "../../common/Blok";
import BOLD from "../../common/BOLD";
import Section from "../../common/Section";
import Title from "../../common/Title";
import PhotoGallery from "../../other/PhotoGallery.tsx";

function Australia(props) {
  return (
    <Section id="australia">
      <Title>Węgrowo</Title>
      <Blok className="text">
        <p>
          Zrealizowany projekt w Węgrowie. Zrealizowany projekt w Małym Rudniku.
          Zrealizowany projekt w Węgrowie. Zrealizowany projekt w Węgrowie.
          Zrealizowany projekt w Węgrowie. Zrealizowany projekt w Węgrowie.{" "}
          <BOLD>Zrealizowany projekt w Węgrowie.</BOLD>Zrealizowany projekt w
          Węgrowie. Zrealizowany projekt w Węgrowie. Zrealizowany projekt w
          Węgrowie.
        </p>
      </Blok>
      <PhotoGallery
        gallery_name={"wegrowo_australia"}
        arrows={props.arrows}
        hover={props.hover}
      ></PhotoGallery>
    </Section>
  );
}

export default Australia;
