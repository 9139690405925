import NavIndent from "./common/NavIndent";
import Australia from "./sites/zrealizowane/Australia";
import Linarczyk from "./sites/zrealizowane/Linarczyk";
import MalyRudnik from "./sites/zrealizowane/MalyRudnik";

function ZrealizowaneProjekty() {
  return (
    <main>
      <NavIndent />
      <Linarczyk bg="WHITE" color="BLACK" arrows="BLACK" hover="GOLD" />
      <MalyRudnik bg="WHITE" color="BLACK" arrows="BLACK" hover="GOLD" />
      <Australia bg="WHITE" color="BLACK" arrows="BLACK" hover="GOLD" />
    </main>
  );
}

export default ZrealizowaneProjekty;
