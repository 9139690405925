import styled from "styled-components";

const IntroStyled = styled.div`
  position: relative;

  & .main_bg {
    position: relative;
    width: 100vw;
    height: 100vh;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
  }

  & a.main-title {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    color: var(--GOLD);
    flex-direction: column;
    font-family: raleway, sans-serif;
    transition: color 0.7s ease 0s;
    text-decoration: none;
    margin: 0px;
    padding: 0px 5vw;
    width: 100%;

    @media (min-width: 1900px) {
      transform: translate(-50%, -30%);
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      transform: translate(-50%, -30%);
    }
    @media (min-width: 480px) and (max-width: 768px) {
      transform: translate(-50%, -30%);
    }
    @media (max-width: 480px) {
      transform: translate(-50%, -30%);
    }

    &:visited {
      color: var(--WHITE);
      text-decoration: none;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--GOLD);
      }
    }

    & h1 {
      margin: 0px;
      padding: 0px;
      text-align: center;
      text-shadow: rgba(0, 0, 0, 0.6) 2px 2px 5px;
      font-size: calc(30vw / 8);

      font-weight: bolder;
      @media (min-width: 1024px) and (max-width: 1300px) {
        font-size: calc(40vw / 8);
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: calc(45vw / 8);
      }
      @media (min-width: 480px) and (max-width: 768px) {
        font-size: calc(50vw / 8);
      }
      @media (max-width: 480px) {
        font-size: calc(60vw / 8);
      }
    }

    & p {
      margin: 0px;
      padding: 0px;
      text-align: center;
      text-shadow: rgba(0, 0, 0, 0.6) 2px 2px 5px;
      font-size: calc(30vw / 10);

      @media (min-width: 1024px) and (max-width: 1300px) {
        font-size: calc(40vw / 10);
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: calc(45vw / 10);
      }
      @media (min-width: 480px) and (max-width: 768px) {
        font-size: calc(50vw / 10);
      }
      @media (max-width: 480px) {
        font-size: calc(60vw / 10);
      }
    }
  }
`;

export default IntroStyled;
