import styled from "styled-components";

const SectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  width: 100vw;
  background-color: var(--WHITE);
  color: var(--BLACK);

  @media (min-width: 480px) and (max-width: 768px) {
    padding-bottom: 2.5rem;
  }
  @media (max-width: 480px) {
    padding-bottom: 2rem;
  }

  &.third-primary {
    background-color: var(--WHITE);
    color: var(--BLACK);
  }

  &.primary-secondary {
    background-color: var(--BLACK);
    color: var(--GOLD);
  }

  &.test {
    background-color: var(--test-color);
    color: var(--test2-color);
  }

  &.sale {
    background-color: var(--WHITE);
  }

  &.offer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & img {
      width: 60vw;
      border: var(--BLACK) solid 1px;
      margin-bottom: 4em;

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 70vw;
      }
      @media (min-width: 480px) and (max-width: 768px) {
        width: 80vw;
      }
      @media (max-width: 480px) {
        width: 80vw;
      }
    }

    & .text-container {
      display: flex;
      justify-content: center;
      width: calc(60vw + 2em);

      & p {
        text-align: justify;
        text-justify: inter-word;

        font-size: 1.4em;
      }
    }
  }
`;

export default SectionStyled;
