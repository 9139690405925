import React from "react";
import {ImgProps} from "../../store/types/Props";

function Img({
  src,
  sizes,
  className,
  alt,
  onMouseDown,
  onMouseMove,
  onClick,
  dataIndex,
}: ImgProps) {
  const srcContent =
    typeof src === "string" ? src : src.widths[src.widths.length - 1].src;
  const srcSet =
    typeof src === "string"
      ? undefined
      : src.widths.map(({src, width}) => `${src} ${width}w`).join(", ");

  return (
    <img
      src={srcContent}
      srcSet={srcSet}
      sizes={sizes}
      alt={alt}
      className={className}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onClick={onClick}
      data-index={dataIndex}
    />
  );
}

export default Img;
