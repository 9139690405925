import Blok from "../../common/Blok";
import BOLD from "../../common/BOLD";
import Section from "../../common/Section";
import Title from "../../common/Title";
import PhotoGallery from "../../other/PhotoGallery.tsx";

function MalyRudnik(props) {
  return (
    <Section id="maly_rudnik">
      <Title>Mały Rudnik</Title>
      <Blok className="text">
        <p>
          Zrealizowany projekt w Małym Rudniku. Zrealizowany projekt w Małym
          Rudniku. Zrealizowany projekt w Małym Rudniku. Zrealizowany projekt w
          Małym Rudniku. Zrealizowany projekt w Małym Rudniku. Zrealizowany
          projekt w Małym Rudniku.{" "}
          <BOLD>Zrealizowany projekt w Małym Rudniku.</BOLD>Zrealizowany projekt
          w Małym Rudniku. Zrealizowany projekt w Małym Rudniku. Zrealizowany
          projekt w Małym Rudniku.
        </p>
      </Blok>
      <PhotoGallery
        gallery_name={"mały_rudnik"}
        arrows={props.arrows}
        hover={props.hover}
      ></PhotoGallery>
    </Section>
  );
}

export default MalyRudnik;
